export default {
    getIsLoggedIn(state) {
        return state.isLoggedIn;
    },
    getPhoneTokenId(state) {
        return state.phoneTokenId;
    },
    getOtpVerification(state) {
        return state.OtpVerification;
    },
};
