import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import pages from "./routes/pages/index";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  routes: [
    ...pages,
    {
      path: "*",
      redirect: `/`,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loginData = Vue.$cookies.get("user-login-data", { parseJSON: true });
  if (to.name == "register" && loginData) {
    next({
      name: "home",
    });
  } else if (to.name == "login" && loginData) {
    next({
      name: "home",
    });
  } else if (to.name == "profile" && !loginData) {
    next({
      name: "login",
    });
  } else if (to.name == "center-booking" && !loginData) {
    next({
      name: "login",
    });
  } else {
    next();
  }
});

export default router;
