export default {
  setCenters: (state, data) => {
    state.allCenters = data.content;
    state.totalCenters = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setCenterDetails: (state, data) => {
    state.centerDetails = data;
  },
};
