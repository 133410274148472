import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import auth from './auth';
import profile from './profile';
import centers from './centers';
import utilities from './utilities';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    auth,
    profile,
    centers,
    utilities,
  },
  strict: process.env.DEV,
});
