<template>
  <div id="app">
    <router-view />
    <div class="spinner-app" v-if="getloading">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollFunction);
  },
  computed: {
    ...mapGetters({
      getloading: "getloading",
    }),
  },
  created() {},
  mounted() {
    const loginData = this.$cookies.get("user-login-data", {
      parseJSON: true,
    });
    window.addEventListener("scroll", this.scrollFunction);
    if (loginData) {
      this.loadProfileInfo();
    }
  },
  methods: {
    ...mapActions({
      loadProfileInfo: "loadProfileInfo",
    }),
    scrollFunction() {
      const navbarId = document.getElementById("navbarId");
      if (navbarId) {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          navbarId.classList.add("navbar_back");
        } else {
          navbarId.classList.remove("navbar_back");
        }
      }
    },
  },
};
</script>
