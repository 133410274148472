export default {
  async loadCenters({ commit }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .get(`customer/centers?page=${data.page}&per_page=${data.per_page}&search=${data.search}&analyses=${data.analyses}&order_by=${data.order_by}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setCenters", respo.data.data);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async loadCentersDetails({ commit }, centerId) {
    commit("loadingStart", null);
    await this._vm.$http
      .get(`customer/centers/${centerId}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setCenterDetails", respo.data.data.content);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
};
