export default [
  {
    path: "/",
    name: "home",
    component: () => import("../../../views/index.vue"),
  },
  {
    path: "/centers",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "centers",
        component: () => import("../../../views/centers.vue"),
      },
      {
        path: "booking/:id",
        name: "center-booking",
        component: () => import("../../../views/booking.vue"),
      },
    ],
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../../../views/profile.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../../../views/register.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../../../views/login.vue"),
  },
];
